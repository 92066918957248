import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Group,
  Col,
  Container,
  Grid,
  MantineProvider,
  Paper,
  createStyles,
  MantineTheme,
  Image,
  Text,
  NavLink,
  Navbar,
  Flex,
  Burger,
  Drawer,
  Center,
  Menu,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconLogin, IconUser } from "@tabler/icons-react";
import logo from "./logo.jpg";
import { UserContext } from "../../App";


const useStyles = createStyles((theme: MantineTheme) => ({
  navBar: {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
    padding: theme.spacing.xs,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
  },
  navButton: {
    marginRight: theme.spacing.xs,
    marginLeft: theme.spacing.xs,
    color: "#ffffff",
    backgroundColor: "#003580",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  logo: {
    marginRight: theme.spacing.md,
  },
}));

type Scope = 'User' | 'Admin' | 'SuperAdmin'


export default function Homepage({
  setNavbarOpened,
}: {
  setNavbarOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    authenticatedUser,
    setAuthenticatedUser,
  }: { authenticatedUser: IUserSessionInfo; setAuthenticatedUser: any } =
    useContext(UserContext);

  const loginUserFromSession = async () => {
    try {
      let fetchRes = await fetch("/auth/user", {
        method: "POST",
        credentials: "include",
      });
      let response = await fetchRes.json();
      if (response.user._id === undefined) {
        navigate("/user/login");
      } else {
        setAuthenticatedUser(response.user);
        navigate("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogout = async () => {
    try {
      let fetchRes = await fetch("/auth/logout", {
        credentials: "include",
      });
      await fetchRes.json();
      setAuthenticatedUser({
        _id: null,
        email: "",
        first_name: "",
        last_name: "",
        scope: "",
      });
    } catch (e) {
      console.log(e);
    }
  };

  //const navigate = useNavigate();
  const { classes } = useStyles();

  const storeNames = ['STEELES', 'WEB', 'Kingston', 'VAUGHAN', 'NIAGARA', 'ALLISTON',
    'SCARBOROUGH', 'CARTWRIGHT', 'BRAMPTON', 'PICKERING', 'YORKGATE', 'OPM-HAMILTON', 'SC SuperStore'];
  const isTabletOrMobile = useMediaQuery("(max-width: 1100px)");

  const storeNamesMenu = (
    <Paper px="md" shadow="xs">
      <Menu>
        {storeNames.map((store) => (
          <Link key={store} to={`/storeData/${store}`}>
            <Menu.Item>{store.toUpperCase()}</Menu.Item>
          </Link>
        ))}
      </Menu>
    </Paper>
  );

  //
  function getNavItemsForScope(scope: Scope | null): string[] {
    if (!scope) return [];

    switch (scope) {
      case 'User':
        return ['/storeData', '/printLabel', '/floorUser'];
      case 'Admin':
        return ['/allocCal', '/floorUser', '/allocData', '/styleData', '/styleqty', '/printLabel', '/poData', '/poWorksheet'];
      case 'SuperAdmin':
        return [
          '/allocCal',
          '/poData',
          '/floorUser',
          '/storeData',
          '/allocData',
          '/styleData',
          '/styleqty',
          '/printLabel',
          '/users',
          '/poWorksheet',
          '/deleteAlloc',
          '/modifyAlloc',
          '/transfer',
        ];
      default:
        return [];
    }
  }

  const NavButtons = (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'le' }}>

      <Navbar.Section grow>
        <Grid justify="flex-start" align="center" gutter="md">
          <Col span={2}>
            <Link to="/" onClick={() => navigate("/")}>
              <Image
                height={55}
                width={100}
                src={logo}
                alt="SVP SPORTS"
                withPlaceholder
                placeholder={
                  <Text>This image contained the meaning of life</Text>
                }
                className={classes.logo}
              />
            </Link>
          </Col>
          <Col offset={1} span={4}>
            <Flex justify="flex-start" gap={"md"} align="center" direction="row" >


              {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/allocCal') && (
                <Button
                  className={classes.navButton}
                  onClick={() => navigate("/allocCal")}
                >
                  Allocation Calculator
                </Button>
              )}
              {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/floorUser') && (
                <Button
                  className={classes.navButton}
                  onClick={() => navigate("/floorUser")}
                >
                  Floor User
                </Button>
              )}

              {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/allocData') && (
                <Menu>
                  <Menu.Target>
                    <Button className={classes.navButton}>Operations</Button>
                  </Menu.Target>
                  <Menu.Dropdown>

                    <Menu.Item onClick={() => navigate("/printLabel")}>
                      Print Label
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/poData")}>
                      Style Alloc
                    </Menu.Item>

                  </Menu.Dropdown>
                </Menu>
              )}

              {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/poWorksheet') && (
                <Menu>
                  <Menu.Target>
                    <Button className={classes.navButton}>Inventory Management</Button>
                  </Menu.Target>
                  <Menu.Dropdown>

                    <Menu.Item onClick={() => navigate("/poWorksheet", { state: "/allocData" })}>
                      Transfer List
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/styleData")}>
                      Style List
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/poWorksheet", { state: "/styleqty" })}>
                      PO Worksheet
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>

              )}
              {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/users') && (
                <Menu>
                  <Menu.Target>
                    <Button className={classes.navButton}>Admin Panel</Button>
                  </Menu.Target>
                  <Menu.Dropdown>

                    <Menu.Item onClick={() => navigate("/users")}>
                      User List
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/deleteAlloc")}>
                      Delecte Allocation
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/user/register")}>
                      Register
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/modifyAlloc")}>
                      Modify Allocation
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/transfer")}>
                      Automate Transfer
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>

              )}
            </Flex>
          </Col>
          <Col offset={3} span={2}>
            <Flex justify={"flex-end"} align="center" direction="row" >
              {authenticatedUser._id !== null &&
                authenticatedUser._id !== undefined ? (
                <>
                  <NavLink
                    pt={"md"}
                    pb={"md"}
                    icon={<IconUser />}
                    label={
                      <React.Fragment>
                        <Text>
                          {authenticatedUser.first_name}{" "}
                          {authenticatedUser.last_name}
                        </Text>
                        <Text color="dimmed">{authenticatedUser.email}</Text>
                      </React.Fragment>
                    }
                    defaultOpened={false}
                  >
                    <NavLink
                      label={<Text>Profile</Text>}
                      active={location.pathname === "/user/login"}
                      onClick={() => {
                        setNavbarOpened(false);
                        navigate("/user/login");
                      }}
                    />
                    <NavLink label={<Text>Logout</Text>} onClick={handleLogout} />
                  </NavLink>
                </>
              ) : (
                <NavLink
                  pt={"md"}
                  pb={"md"}
                  icon={<IconLogin />}
                  label={<Text>LogIn</Text>}
                  onClick={() => {
                    setNavbarOpened(false);
                    loginUserFromSession();
                  }}
                />
              )}
            </Flex>
          </Col>
        </Grid>
      </Navbar.Section>
    </div>
  );

  const [opened, setOpened] = useState(false);

  const BurgerMenu = (
    <>
      <Flex gap={350} align={Center} justify={Center}>
        <Burger opened={opened} onClick={() => setOpened(!opened)} size="xl" />

        <Link to="/" onClick={() => navigate("/")}>
          <Image
            height={55}
            width={100}
            src={logo}
            alt="SVP SPORTS"
            withPlaceholder
            placeholder={<Text>This image contained the meaning of life</Text>}
            className={classes.logo}
          />
        </Link>
      </Flex>
    </>
  );

  const NavDrawer = (
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      padding="xs"
      size="20%"
      position="left"
      transitionProps={{
        transition: "rotate-left",
        duration: 150,
        timingFunction: "linear",
      }}
    >
      <Flex direction="column" style={{ marginTop: "35px" }}>
        {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/allocCal') && (
          <NavLink
            label={<Text>Allocation Calculator</Text>}
            onClick={() => navigate("/allocCal")}
          />
        )}
        {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/floorUser') && (
          <NavLink
            label={<Text>Floor User</Text>}
            onClick={() => navigate("/floorUser")}
          />
        )}

        {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/printLabel') && (
          <NavLink
            label="Operations"
            childrenOffset={28}
          >
            <NavLink
              label={<Text>Print Label</Text>}
              onClick={() => navigate("/printLabel")}
            />
            <NavLink
              label={<Text>Style Alloc</Text>}
              onClick={() => navigate("/poData")}
            />
          </NavLink>
        )}
        {/* {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/poData') && (
            <NavLink
            className={classes.navButton}
            onClick={() => navigate("/poData")}
          >
            Style Alloc
          </NavLink>
        )} */}
        {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/allocData') && (
          <NavLink
            label="Inventory Management"
            childrenOffset={28}
          >
            <NavLink
              label={<Text>Transfer List</Text>}
              onClick={() => navigate("/poWorksheet", { state: "/allocData" })}
            />
            <NavLink
              label={<Text>Style List</Text>}
              onClick={() => navigate("/styleData")}
            />
            <NavLink
              label={<Text>PO Worksheet</Text>}
              onClick={() => navigate("/poWorksheet", { state: "/styleqty" })}
            />
          </NavLink>

        )}
        {/* {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/styleData') && (
          <NavLink
            label={<Text>Style List</Text>}
            onClick={() => navigate("/styleData")}
          />
        )}
        {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/poWorksheet') && (
          <NavLink
            label={<Text>PO Worksheet</Text>}
            onClick={() => navigate("/poWorksheet", { state: "/styleqty" })}
          />
        )} */}
        {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/users') && (
          <NavLink
            label="Admin Panel"
            childrenOffset={28}
          >
            <NavLink
              label={<Text>Users</Text>}
              onClick={() => navigate("/users")}
            />
            <NavLink
              label={<Text>Register</Text>}
              onClick={() => navigate("/user/register")}
            />
            <NavLink
              label={<Text>Delete Alloc</Text>}
              onClick={() => navigate("/deleteAlloc")}
            />
            <NavLink
              label={<Text>Modify Alloc</Text>}
              onClick={() => navigate("/modifyAlloc")}
            />
            <NavLink
              label={<Text>Automate transfer</Text>}
              onClick={() => navigate("/transfer")}
            />

          </NavLink>
        )}
        {/* {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/deleteAlloc') && (
          <NavLink
            label={<Text>Delete Alloc</Text>}
            onClick={() => navigate("/deleteAlloc")}
          />
        )} */}
        {/* {getNavItemsForScope(authenticatedUser.scope as Scope | null).includes('/users') && (
          <NavLink
            label={<Text>Register</Text>}
            onClick={() => navigate("/user/register")}
          />
        )} */}
        {authenticatedUser._id !== null &&
          authenticatedUser._id !== undefined ? (
          <>
            <NavLink
              pt={"md"}
              pb={"md"}
              icon={<IconUser />}
              label={
                <React.Fragment>
                  <Text>
                    {authenticatedUser.first_name} {authenticatedUser.last_name}
                  </Text>
                  <Text color="dimmed">{authenticatedUser.email}</Text>
                </React.Fragment>
              }
              defaultOpened={false}
            >
              <NavLink
                label={<Text>Profile</Text>}
                active={location.pathname === "/user/login"}
                onClick={() => {
                  setNavbarOpened(false);
                  navigate("/user/login");
                }}
              />
              <NavLink label={<Text>Logout</Text>} onClick={handleLogout} />
            </NavLink>
          </>
        ) : (
          <NavLink
            pt={"md"}
            pb={"md"}
            icon={<IconLogin />}
            label={<Text>Click here to login</Text>}
            onClick={() => {
              setNavbarOpened(false);
              loginUserFromSession();
            }}
          />
        )}
      </Flex>
    </Drawer>
  );

  return (
    <MantineProvider>
      <Paper shadow="xl" className={classes.navBar} key={'Paper'} id="Paper">
        <Container size="100rem" px={0} key={'Container'} id="Container">
          <Navbar.Section grow key={'Section'} id="Section">
            <Grid align="center" grow key={'Grid'} id="Grid">
              <Col span="content" key={'Col'} id="Col">
                <Group position="apart" align="left" key={'Group'} id="Group">
                  {/* Switch between buttons and menu based on screen size */}
                  {isTabletOrMobile ? BurgerMenu : NavButtons}

                  {isTabletOrMobile && NavDrawer}
                </Group>
              </Col>
            </Grid>
          </Navbar.Section>
        </Container>
      </Paper>
    </MantineProvider>
  );
}
