/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Center, Checkbox, Col, Container, Flex, Grid, Group, Input, MantineProvider, Modal, Popover, Table, Text } from '@mantine/core';
import axios from 'axios';
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Header from './styleInputTable';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import "../Styles/allocationCalculator.css";


pdfMake.vfs = pdfFonts.pdfMake.vfs;







type SizeQuantity = {
  size: string;
  quantity: number;
};
type StoreData = {
  storeName: string;
  sizeQuantities: SizeQuantity[];
};

interface AllocProps { }

const Alloc: React.ForwardRefRenderFunction<{ reset: () => void }, AllocProps> = (_props, ref) => {
  const [sizes, setSizes] = useState<string[]>([]);

  const [tableData, setTableData] = useState<StoreData[]>([]);
  const [totalProductionQuantities, setTotalProductionQuantities] = useState<number[]>([]);
  const [standardQuantities, setStandardQuantities] = useState<number[]>(new Array(15).fill(0));
  const [totalProductionSum, setTotalProductionSum] = useState<number>(0);
  const [calculateClicked, setCalculateClicked] = useState(false);
  const [totalStdQty, setTotalStdQty] = useState(0);
  const [overstockStore, setOverstockStore] = useState<string | null>(null);
  const [supplierName, setSupplierName] = useState('');
  const [styleNo, setStyleNo] = useState('');
  const [styleId, setStyleId] = useState(0);
  const [poId, setPoId] = useState('');
  const [notes, setNotes] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState('');
  const [cost, setCost] = useState('');
  const [msrp, setMsrp] = useState('');
  const [poNo, setPoNo] = useState('');
  const [binLocation, setBinLocation] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeRow, setActiveRow] = useState<string | null>(null);
  const [sizeInput, setSizeInput] = useState<string>('');
  const [createResponse, setCreateResponse ] = useState<{
    poId: string;
    poNo: string;
    styleId: number;
    styleNo: string;
  }>({ poId: '', poNo: '', styleId: 0, styleNo: ''});


  const storeNames = [
    'STEELES', 'WEB', 'SC SuperStore', 'Brampton', 'SVP NM', 'ORFUS', 'VAUGHAN','SVP QW', 'Kingston', 'NIAGARA', 'ALLISTON', 'OPM-HAMILTON',
    'CARTWRIGHT', 'PICKERING', 'YORKGATE'
  ];

  const [stores, setStores] = useState<string[]>(storeNames);
  const [allChecked, setAllChecked] = useState<boolean>(true);


  const generateLandscapePDF = async (): Promise<void> => {
    const body: any[] = [];

    const header = [
      { text: 'Store', style: 'tableHeader' },
      ...sizes.map(size => ({ text: size, style: 'tableHeader' })),
      { text: 'Total', style: 'tableHeader' },
      { text: 'INITIALS', style: 'tableHeader' }
    ]
    body.push(header);

    const totalProductionQty = ['RCV QTY', ...sizes.map((_, index) => totalProductionQuantities[index]), totalProductionSum, ''];
    body.push(totalProductionQty);

    // Add your table data here, looping through tableData, for example
    tableData.forEach((row, index: number) => {
      const rowData = [
        row.storeName,
        ...row.sizeQuantities.map(sq => sq.quantity),
        calculateRowTotal(index),
        ''
      ];
      body.push(rowData);
    });
    // Add total allocation row
    const totalAllocationRow = ['Total Allocation', ...sizes.map((_, index) => calculateTotalAllocation(index)), calculateTotalSizeQuantitiesSum(), 0];
    body.push(totalAllocationRow);

    // Add overstock row
    const overstockRow = ['Overstock', ...sizes.map((_, index) => calculateOverstock(index)), Math.max(totalProductionSum - calculateTotalSizeQuantitiesSum()), 0];
    body.push(overstockRow);




    let Landscapecontent: Content[] = [
      {

        stack: [
          {
            text: 'SVP SPORTS - DISTRIBUTION CENTER',
            fontSize: 24,
            bold: true,
            alignment: 'center',
            margin: [0, 0, 0, 10]
          },
          { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
          {
            columns: [
              { text: `#PO: ${poNo}`, style: 'header' },
              { text: `Supplier: ${supplierName}`, style: 'header' },
            ],
            columnGap: 10,
          },
          { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
          {
            columns: [
              { text: `StyleNo: ${styleNo}`, style: 'header' },
            ],
            columnGap: 10,
          },
          {
            columns: [
              { text: `Description: ${description}`, style: 'subheader' },
              { text: `Bin: ${binLocation}`, style: 'subheader' },
             
            ],
            columnGap: 8,
          },
          { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
          {
            columns: [
              { text: `Color: ${color}`, style: 'subheader' },
              { text: `Cost: ${cost}`, style: 'subheader' },
              { text: `MSRP: ${msrp}`, style: 'subheader' },
            ],
            columnGap: 5,
          },
          { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
        ],
      },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            alignment: 'center',
            table: {
              body: body,
              widths: ['auto', ...new Array(sizes.length).fill('*'), 'auto', 'auto'],
            },
            layout: {
              hLineWidth: () => 1,
              vLineWidth: () => 1,
              hLineColor: () => '#d0d0d0',
              vLineColor: () => '#d0d0d0',
              paddingLeft: () => 1,
              paddingRight: () => 1,
              paddingTop: () => 1,
              paddingBottom: () => 1,
            },
            style: {
              fontSize: 10
            }
          },
          { width: '*', text: '' }
        ],
        columnGap: 10
      }
    ];

    const LandscapedocDefinition: TDocumentDefinitions = {
      pageSize: 'LETTER',
      pageOrientation: 'landscape',
      content: Landscapecontent, // Assign the prepared content here
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 14,
          bold: true,
          alignment: 'center',
          margin: [0, 10, 0, 5]
        },
        subheader1: {
          fontSize: 14,
          bold: true,
          alignment: 'center',
          margin: [0, 10, 0, 35]
        },
        tableHeader: {
          fillColor: '#f2f2f2', // light gray background
          bold: true,
          alignment: 'center',
          margin: [2, 2, 2, 2]
        },
      }
    };

    const pdfDoc = pdfMake.createPdf(LandscapedocDefinition);
    pdfDoc.open();
  };

  //
  //Chunk Size
  const chunkSizes = (sizes: string[], chunkSize: number): string[][] => {
    const chunks = [];
    for (let i = 0; i < sizes.length; i += chunkSize) {
      chunks.push(sizes.slice(i, i + chunkSize));
    }
    return chunks;
  };


  // Specific Size Table
  const createSizeSpecificTable = (size: string, tableData: StoreData[]): (string | number)[][] => {

    const headers = [`${styleNo}`, `Qty (Size: ${size})`];
    const styledHeader = headers.map(header => ({ text: header, style: 'tableHeader' }));

    const body: any[][] = [styledHeader];

    tableData.forEach(store => {
      const sizeQuantity = store.sizeQuantities.find(sq => sq.size === size);
      const quantity = sizeQuantity ? sizeQuantity.quantity.toString() : '0';
      body.push([store.storeName, quantity]);
    });

    return body;
  };


  const createThreeByThreeGrid = (chunk: string[], allocationData: any): Content => {
    const tableLayout = {
      hLineWidth: () => 1,
      vLineWidth: () => 1,
      hLineColor: () => '#d0d0d0',
      vLineColor: () => '#d0d0d0',
      paddingLeft: () => 3,
      paddingRight: () => 3,
      paddingTop: () => 1,
      paddingBottom: () => 1,
    };
    let gridRows: Content[] = [];

    for (let rowIndex = 0; rowIndex < 2; rowIndex++) {
      let rowColumns: Content[] = [];
      for (let columnIndex = 0; columnIndex < 3; columnIndex++) {
        const sizeIndex = rowIndex * 3 + columnIndex;
        if (sizeIndex < chunk.length) {
          const sizeTable = createSizeSpecificTable(chunk[sizeIndex], allocationData);
          rowColumns.push({
            table: {
              body: sizeTable,
              widths: ['50%', 'auto'],
            },
            margin: [0, 0, 8, 15],
            fontSize: 11,
            layout: tableLayout
          });
        } else {
          rowColumns.push({ text: '' });
        }
      }
      gridRows.push({ columns: rowColumns, columnGap: 4 });

      // Add a dotted line after each row except the last one
      if (rowIndex < 2) {
        gridRows.push({
          canvas: [
            {
              type: 'line',
              x1: 0, y1: 5,
              x2: 520, y2: 6, // Adjust the length as needed
              lineWidth: 1,
              dash: { length: 4 },
              lineColor: '#000000'
            }
          ],
          margin: [0, 3, 0, 3] // Adjust margin as needed
        });
      }
    }


    return {
      unbreakable: true,
      stack: gridRows
    };
  };


 





  const generatePortraitPDF = async (sizeChunks: string[][], tableData: StoreData[]): Promise<void> => {
    const body: any[] = [];
    let portraitContent: Content[] = [];

    sizeChunks.forEach(chunk => {
      const gridPage = createThreeByThreeGrid(chunk, tableData);
      portraitContent.push({
        pageOrientation: 'portrait',
        stack: [gridPage],
        style: 'sizeTable'
      });
    });

    const PortraitdocDefinition: TDocumentDefinitions = {
      pageSize: 'LETTER',
      pageOrientation: 'portrait',
      content: portraitContent,
      styles: {
        sizeTable: {
          fontSize: 10, // Adjust font size as needed
          margin: [5, 5, 5, 5]
        },
        tableHeader: {
          fillColor: '#f2f2f2', // light gray background
          bold: true,
          alignment: 'center',

        },
      }
    };


    // Create the PDF and open it in a new window
    const pdfDoc = pdfMake.createPdf(PortraitdocDefinition);
    pdfDoc.open();
  };


  const downloadAllPDFs = async () => {
    await generateLandscapePDF();
    await generatePortraitPDF(chunkSizes(sizes, 6), tableData);
  };


  useEffect(() => {
    updateTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizes, stores]);

  useEffect(() => {
    const newSum = totalProductionQuantities.reduce((total, currentQuantity) => total + currentQuantity, 0);
    setTotalProductionSum(newSum);
  }, [totalProductionQuantities]);

  const onSave = async () => {
    let tempPoId = poId;
    if(poId === null || poId === undefined) {
      await setPoId(poNo.toUpperCase());
      tempPoId = poId; 
    } 
    if(!poNo || !supplierName || !styleNo || !cost || !msrp || poNo.trim() === '' || supplierName.trim() === '' || styleNo.trim() === '' || cost.trim() === '' || msrp.trim() === '') {
      alert('Please fill in all required fields');
      return; 
    }
    let noteWithBin = binLocation.trim() === '' ? notes : `${notes} | Bin Location: ${binLocation}`;
    const statusArray = Array(tableData.length).fill(false);
    const initialArray = Array(tableData.length).fill('SVP');
    const data = {
      poNo: poNo,
      po_id: tempPoId,
      supplier_name: supplierName,
      styleNo: styleNo,
      description: description,
      color: color,
      cost: cost,
      msrp: msrp,
      notes: noteWithBin,
      status: statusArray,
      storeName: tableData.map(data => data.storeName),
      sizeQuantities: tableData.map(data => data.sizeQuantities),
      receivedQty: sizes.map((size, index) => ({
        size: size,
        quantity: totalProductionQuantities[index],
      })),
      total: totalProductionQuantities.reduce((a, b) => a + b, 0),
      totalAllocationPerSize: sizes.map((_size, index) => calculateTotalAllocation(index)),
      overstockPerSize: sizes.map((_size, index) => calculateOverstock(index)),
      // assuming that allocationId is available
      initial: initialArray
    };

    try {
      const response = await axios.post('/allocation/create', data, { withCredentials: true });

      if (response.status === 200) {
        alert('Data saved successfully');
        setIsError(false);
        reset(true); // Pass true to skip save confirmation
      } else if(response.status === 400) {
        alert('Style No already exists');
        setMessage('Error while saving data');
        setIsError(true);
      }else{
        alert(message? message : 'Error while saving data');
      }
      setModalIsOpen(true); // Open the modal
        setCreateResponse({
          poId: response.data.purchaseOrder.po_id,
          poNo: response.data.purchaseOrder.po_no,
          styleId: response.data.style[0].style_id,
          styleNo: response.data.style[0].style_no,
        });
      // }
    } catch (error: any) {
      setMessage('Error while saving data: ' + error.message);
      setIsError(true);
      setModalIsOpen(true); // Open the modal
    }
  };


  const updateTableData = () => {
    const data = stores.map(store => {
      return {
        storeName: store,
        sizeQuantities: sizes.map(size => {
          return {
            size: size,
            quantity: 0,
          };
        }),
      };
    });
    setTableData(data);
  };
  const handleSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSizeInput(event.target.value);
    const newSizes = event.target.value.toUpperCase().split(',').map(size => size.trim());
    setSizes(newSizes);
    setTotalProductionQuantities(new Array(newSizes.length).fill(0)); // Reset total production quantities
  };

  //handle Calculate
  //handle Calculate
  const priorityStores = [
    'STEELES', 'WEB', 'SC SuperStore', 'Kingston', 'VAUGHAN', 'BRAMPTON', 'OPM-HAMILTON', 'NIAGARA', 'SCARBOROUGH', 'CARTWRIGHT', 'ALLISTON', 'PICKERING', 'YORKGATE'
  ];

  const createPriorities = (storesData: StoreData[]): StoreData[] => {
    // Pair store names with their standard quantities and sort by quantity descending
    const storePriorities = storesData.map((store, index) => ({
      name: store.storeName,
      quantity: standardQuantities[index], // Assume standardQuantities are aligned with storesData
      storeData: store
    }));

    // Sort by standard quantity in descending order
    storePriorities.sort((a, b) => b.quantity - a.quantity);

    // Return the sorted store data based on the priority
    return storePriorities.map(sp => sp.storeData);
  };
  const allocateSizesToStores = (): void => {
    const totalStandardQuantity = standardQuantities.reduce((sum, quantity) => sum + quantity, 0);
    const totalProduction = totalProductionQuantities.reduce((sum, quantity) => sum + quantity, 0);

    // Check if total standard quantity exceeds total production quantity
    if (totalStandardQuantity > totalProduction) {
      alert("Total standard quantity exceeds total production quantities. Allocation cannot proceed.");
      return; // Exit the function early
    }

    let totalRemainingQuantities = [...totalProductionQuantities];
    const prioritizedStores = createPriorities(tableData);

    for (const storeData of prioritizedStores) {
      const storeIndex = tableData.indexOf(storeData);
      const standardQuantity = standardQuantities[storeIndex];
      let storeTotalAllocation = 0;

      // Calculate total quantities remaining before this store's allocation
      const totalAvailable = totalRemainingQuantities.reduce((sum, quantity) => sum + quantity, 0);

      for (let sizeIndex = 0; sizeIndex < storeData.sizeQuantities.length; sizeIndex++) {
        if (totalAvailable > 0) {
          const sizeProportion = totalRemainingQuantities[sizeIndex] / totalAvailable;
          let allocation = Math.round(standardQuantity * sizeProportion);
          allocation = Math.min(allocation, totalRemainingQuantities[sizeIndex]);
          storeData.sizeQuantities[sizeIndex].quantity = allocation;


          storeTotalAllocation += allocation;
        }
      }

      // Check if total allocation is less than standard and try to increase it
      if (storeTotalAllocation < standardQuantity) {
        for (let sizeIndex = 0; sizeIndex < storeData.sizeQuantities.length; sizeIndex++) {
          const potentialIncrease = Math.min(standardQuantity - storeTotalAllocation, totalRemainingQuantities[sizeIndex]);
          storeData.sizeQuantities[sizeIndex].quantity += potentialIncrease;
          totalRemainingQuantities[sizeIndex] -= potentialIncrease;
          storeTotalAllocation += potentialIncrease;
        }
      }
    }

    // After all stores have been processed, make final adjustments
    adjustAllocations(tableData, totalProductionQuantities.reduce((sum, quantity) => sum + quantity, 0));
    validateTotalAllocations(tableData);
    setTableData(tableData);
  };




  const validateTotalAllocations = (storesData: StoreData[]): void => {
    // First, correct any over-allocation
    storesData.forEach(storeData => {
      storeData.sizeQuantities.forEach((sizeData, sizeIndex) => {
        const totalAllocated = storesData.reduce((sum, store) => sum + store.sizeQuantities[sizeIndex].quantity, 0);
        if (totalAllocated > totalProductionQuantities[sizeIndex]) {
          let excess = totalAllocated - totalProductionQuantities[sizeIndex];

          // Start reduction from the least prioritized stores
          for (let i = storesData.length - 1; i >= 0 && excess > 0; i--) {
            let storeSizeQuantity = storesData[i].sizeQuantities[sizeIndex];
            let reduction = Math.min(storeSizeQuantity.quantity, excess);
            storeSizeQuantity.quantity -= reduction;
            excess -= reduction;
          }
        }
      });
    });

    // // Then, ensure each store's allocation meets its standard quantity if possible, distributing across sizes
    // storesData.forEach((storeData, storeIndex) => {
    //     let needed = standardQuantities[storeIndex] - storeData.sizeQuantities.reduce((sum, sizeData) => sum + sizeData.quantity, 0);

    //     while (needed > 0) {
    //         // Distribute the needed quantity across all sizes proportionally
    //         const remainingQuantities = storeData.sizeQuantities.map((sizeData, sizeIndex) => 
    //             totalProductionQuantities[sizeIndex] - storesData.reduce((sum, store) => sum + store.sizeQuantities[sizeIndex].quantity, 0)
    //         );

    //         // Calculate the proportional allocation for each size
    //         let totalRemaining = remainingQuantities.reduce((a, b) => a + b, 0);
    //         if (totalRemaining <= 0) break;

    //         for (let sizeIndex = 0; sizeIndex < storeData.sizeQuantities.length; sizeIndex++) {
    //             if (needed <= 0) break;
    //             let proportion = remainingQuantities[sizeIndex] / totalRemaining;
    //             let increase = Math.min(needed, Math.floor(proportion * needed), remainingQuantities[sizeIndex]);
    //             storeData.sizeQuantities[sizeIndex].quantity += increase;
    //             needed -= increase;
    //         }
    //     }
    // });
  };

  const adjustAllocations = (storesData: StoreData[], totalReceived: number): void => {
    let remainingQuantities = totalProductionQuantities.map((total, index) =>
      total - storesData.reduce((sum, store) => sum + store.sizeQuantities[index].quantity, 0)
    );

    const prioritizedStores = createPriorities(storesData);

    prioritizedStores.forEach(storeData => {
      const storeIndex = storesData.indexOf(storeData);
      const standardQuantity = standardQuantities[storeIndex] || 0;
      let storeTotalAllocation = storeData.sizeQuantities.reduce((sum, sizeData) => sum + sizeData.quantity, 0);

      if (storeTotalAllocation > standardQuantity) {
        scaleDownStoreAllocation(storeData, standardQuantity);
        storeTotalAllocation = storeData.sizeQuantities.reduce((sum, sizeData) => sum + sizeData.quantity, 0);
      }

      // Redistribute remaining quantities to stores under their standard allocation
      storeData.sizeQuantities.forEach((sizeData, sizeIndex) => {
        if (remainingQuantities[sizeIndex] > 0 && storeTotalAllocation < standardQuantity) {
          let additionalAllocation = Math.min(remainingQuantities[sizeIndex], standardQuantity - storeTotalAllocation);
          sizeData.quantity += additionalAllocation;
          remainingQuantities[sizeIndex] -= additionalAllocation;
          storeTotalAllocation += additionalAllocation;
        }
      });
    });

    processDiscrepancies(storesData, remainingQuantities);
  };

  const processDiscrepancies = (storesData: StoreData[], remainingQuantities: number[]) => {
    storesData.forEach((storeData, storeIndex) => {
      const standardQuantity = standardQuantities[storeIndex];

      // Skip processing for stores with a standard quantity of zero
      if (standardQuantity === 0) return;

      storeData.sizeQuantities.forEach((sizeData, sizeIndex) => {
        // Allocate remaining sizes to stores that have not received any, ensuring all sizes are allocated
        if (remainingQuantities[sizeIndex] > 0 && sizeData.quantity === 0) {
          let allocation = Math.min(1, remainingQuantities[sizeIndex]); // Allocate at least one unit if possible
          sizeData.quantity += allocation;
          remainingQuantities[sizeIndex] -= allocation;
        }
      });
    });

    // Ensure no store's allocation exceeds its standard quantity
    storesData.forEach((storeData, storeIndex) => {
      const standardQuantity = standardQuantities[storeIndex];
      // Continue to ensure no store's allocation exceeds the defined standard quantity
      if (storeData.sizeQuantities.reduce((sum, sizeData) => sum + sizeData.quantity, 0) > standardQuantity) {
        scaleDownStoreAllocation(storeData, standardQuantity);
      }
    });
  };


  const scaleDownStoreAllocation = (storeData: StoreData, standardQuantity: number) => {
    let reductionNeeded = storeData.sizeQuantities.reduce((sum, sizeData) => sum + sizeData.quantity, 0) - standardQuantity;

    if (reductionNeeded > 0) {
      const reducibleSizes = storeData.sizeQuantities.filter(sizeData => sizeData.quantity > 1);
      const totalReducibleQuantity = reducibleSizes.reduce((sum, sizeData) => sum + sizeData.quantity, 0);

      reductionNeeded = Math.min(reductionNeeded, totalReducibleQuantity);

      reducibleSizes.forEach(sizeData => {
        const reductionRatio = sizeData.quantity / totalReducibleQuantity;
        const reductionAmount = Math.ceil(reductionNeeded * reductionRatio);
        // Ensure not reducing below 1
        const newQuantity = Math.max(sizeData.quantity - reductionAmount, 1);
        reductionNeeded -= (sizeData.quantity - newQuantity);
        sizeData.quantity = newQuantity;
      });

      // Handle any remaining reduction needed due to rounding, ensuring quantities do not drop below 1
      if (reductionNeeded > 0) {
        const largestSizeData = reducibleSizes.sort((a, b) => b.quantity - a.quantity)[0];
        largestSizeData.quantity = Math.max(largestSizeData.quantity - reductionNeeded, 1);
      }
    }
  };










  // Use useEffect to recalculate allocations when needed
  useEffect(() => {
    if (calculateClicked) {
      allocateSizesToStores();
      setCalculateClicked(false);
    }
  }, [standardQuantities, totalProductionQuantities, calculateClicked]);

  // Handler for button click
  const handleCalculateClick = () => {
    allocateSizesToStores();
    setCalculateClicked(true);
  };



  //Calculate OVERSTOCK
  const allocateOverstockToStore = (tableData: StoreData[], overstockStore: string | null) => {
    if (!overstockStore) {
      return tableData;
    }

    const overstockStoreData = tableData.find(storeData => storeData.storeName === overstockStore);
    if (!overstockStoreData) {
      return tableData;
    }

    const newTableData = [...tableData];

    overstockStoreData.sizeQuantities.forEach((sizeData, sizeIndex) => {
      const overstock = calculateOverstock(sizeIndex);
      if (overstock > 0) {
        sizeData.quantity += overstock;
      }
    });

    return newTableData;
  };




  const handleAllCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);

    if (event.target.checked) {
      // If "Check All" is checked, add all store names to the stores state
      setStores(storeNames);
      const newData = storeNames.map((storeName) => {
        return {
          storeName: storeName,
          sizeQuantities: sizes.map((size) => {
            return {
              size: size,
              quantity: 0,
            };
          }),
        };
      });
      setTableData(newData);
    } else {
      // If "Check All" is unchecked, clear the stores and tableData states
      setStores([]);
      setTableData([]);
      setStandardQuantities([]);
    }
  };

  const handleStoreChange = (event: ChangeEvent<HTMLInputElement>, storeName: string): void => {
    const checked = event.target.checked;
    let newTableData = [...tableData];
    let newStandardQuantities = [...standardQuantities];

    if (checked) {
      if (!newTableData.some(data => data.storeName === storeName)) {
        // Add new store data
        const newSizeQuantities = sizes.map(size => ({ size, quantity: 0 }));
        newTableData.push({ storeName, sizeQuantities: newSizeQuantities });
        // Ensure there is a corresponding entry in standardQuantities
        newStandardQuantities.push(0); // Assumes default standard quantity is 0
      }
    } else {
      // Find index of the store to remove
      const index = newTableData.findIndex(data => data.storeName === storeName);
      if (index !== -1) {
        // Remove store data
        newTableData.splice(index, 1);
        // Remove the corresponding standard quantity
        newStandardQuantities.splice(index, 1);
      }
    }

    // Reorder the newTableData based on storeNames
    newTableData.sort((a, b) => storeNames.indexOf(a.storeName) - storeNames.indexOf(b.storeName));

    setTableData(newTableData);
    setStandardQuantities(newStandardQuantities); // Update standard quantities to reflect changes
    setStores(newTableData.map(data => data.storeName)); // Update the stores state to reflect the current stores
  };









  const handleQuantityChange = (
    event: ChangeEvent<HTMLInputElement>,
    storeIndex: number,
    sizeIndex: number
  ) => {
    const newData = [...tableData];
    newData[storeIndex].sizeQuantities[sizeIndex].quantity = parseInt(
      event.target.value,
      10
    );
    setTableData(newData);
  };

  const handleTotalProductionQuantityChange = (event: ChangeEvent<HTMLInputElement>, sizeIndex: number) => {
    const newTotalProductionQuantity = parseInt(event.target.value, 10) || 0;
    setTotalProductionQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities];
      newQuantities[sizeIndex] = newTotalProductionQuantity;
      return newQuantities;
    });
  };

  const handleStandardQuantityChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newQuantity = parseInt(event.target.value, 10) || 0;
    setStandardQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = newQuantity;
      return newQuantities;
    });
  };




  const calculateTotalAllocation = (sizeIndex: number) => {
    return tableData.reduce(
      (total, row) => {
        // Check if row and row.sizeQuantities are not null
        if (row && row.sizeQuantities) {
          // Check if sizeIndex is within the bounds of the sizeQuantities array
          if (sizeIndex < row.sizeQuantities.length) {
            return total + row.sizeQuantities[sizeIndex].quantity;
          }
        }
        return total;
      },
      0
    );
  };



  const calculateTotalStdQtySum = (): number => {
    return standardQuantities.reduce((total, currentQuantity) => total + (currentQuantity || 0), 0);
  };
  useEffect(() => {
    const newTotalStdQty = calculateTotalStdQtySum();
    setTotalStdQty(newTotalStdQty);

  }, [standardQuantities, tableData]);


  const calculateRowTotal = (rowIndex: number) => {
    return tableData[rowIndex].sizeQuantities.reduce((total, sizeData) => total + sizeData.quantity, 0);
  };

  const calculateTotalSizeQuantitiesSum = (): number => {
    return tableData.reduce((total, _rowData, rowIndex) => total + calculateRowTotal(rowIndex), 0);
  };


  const calculateOverstock = (sizeIndex: number) => {
    const totalAllocation = calculateTotalAllocation(sizeIndex);
    const totalProductionQuantity = totalProductionQuantities[sizeIndex] || 0;
    const result = Math.max(totalProductionQuantity - totalAllocation);
    return isNaN(result) ? 0 : result;
  };


  const reset = (skipSaveConfirmation = false) => {
    if (!skipSaveConfirmation) {
      const confirmReset = window.confirm("Do you want to reset the form?");
      if (confirmReset) {
        onSave();
        return;
      }
    }


    setTableData([]);
    setStyleNo('');
    setNotes('');
    setColor('');
    setTotalProductionQuantities([]);
    setStandardQuantities(new Array(15).fill(0));
    setTotalProductionSum(0);
    setCalculateClicked(false);
    setTotalStdQty(0);
    setOverstockStore(null);
    setStores(tableData.map(data => data.storeName));
  };
  useImperativeHandle(ref, () => ({
    reset
  }));





  return (
    <>

      <Header
        poNo={poNo}
        setPoNo={setPoNo}
        supplierName={supplierName}
        setSupplierName={setSupplierName}
        styleNo={styleNo}
        setStyleNo={setStyleNo}
        styleId={styleId}
        setStyleId={setStyleId}
        poId={poId}
        setPoId={setPoId}
        description={description}
        setDescription={setDescription}
        color={color}
        setColor={setColor}
        cost={cost}
        setCost={setCost}
        msrp={msrp}
        setMsrp={setMsrp}
        notes={notes}
        setNotes={setNotes}
        onSave={onSave}  // pass onSave as prop
        sizeInput={sizeInput}
        setSizeInput={setSizeInput}
        sizes={sizes}
        setSizes={setSizes}
        createResponse={createResponse}
        binLocation={binLocation}
        setBinLocation={setBinLocation}
      />
      <Container id='no-print'>
        <Grid gutter="md" justify="center" >

          <Col span={8}>
            <Grid.Col >

              <Text ta="center" fz="lg" fw={600} component="label" htmlFor="size-input-field">
                Sizes:
              </Text>

              <Input
                type="text"
                id="size-input-field"
                value={sizeInput}
                name="size-input-field"
                placeholder="Enter size range"
                onChange={handleSizeChange}
              />
            </Grid.Col>
          </Col>
          <Group spacing="lg">
            <Col span={12}>
              <Grid.Col >
                <Flex justify="center" wrap="wrap" align="center" direction="row" rowGap="xs" columnGap="md">
                  <Checkbox
                    label="Check All"
                    checked={allChecked}
                    onChange={handleAllCheckedChange}
                  />
                  {storeNames.map((storeName) => (
                    <div key={storeName}>
                      <Checkbox
                        label={storeName}
                        checked={stores.includes(storeName)}
                        onChange={(e) => handleStoreChange(e, storeName)}
                      />
                    </div>
                  ))}

                </Flex>
              </Grid.Col>
            </Col>
          </Group>

        </Grid>
      </Container>
      <MantineProvider
        theme={{
          components: {
            Container: {
              defaultProps: {
                sizes: {
                  xs: 540,
                  sm: 720,
                  md: 960,
                  lg: 1140,
                  xl: 2340,
                },
              },
            },
          },
        }}
      >
        <Container style={{ marginTop: "20px" }} size="xl">
          <Grid>
            <Grid.Col span={10} style={{ overflowX: "auto" }}>
              <Table id="table-to-print-1" className="table">
                <thead id='table-header'>
                  <tr>
                    <th style={{ position: 'sticky', left: 0, zIndex: 1, background: '#F6F6F6' }}>Store</th>
                    {sizes.map((size) => (
                      <th key={size}>{size}</th>
                    ))}
                    <th>Total</th>
                    {/* <th> STD QTY</th> */}
                  </tr>
                </thead>
                <tbody style={{ border: '1px solid #d0d0d0', borderRight: '1px solid #d0d0d0' }}>
                  <tr>
                    <td style={{ fontWeight: 'bold', position: 'sticky', left: 0, zIndex: 1, background: '#F6F6F6' }} >RCV QTY</td>
                    {sizes.map((_size, index) => (
                      <td key={`total-production-quantity-${index}`} style={{ borderLeft: '1px solid #d0d0d0' }}>

                        <Input
                          type="number"
                          value={totalProductionQuantities[index] || ""}
                          onChange={(e) => handleTotalProductionQuantityChange(e, index)}
                          size="md"
                          variant="unstyled"
                          style={{ width: '45px' }}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </td>
                    ))}
                    <td style={{ borderRight: '1px solid #d0d0d0', borderLeft: '1px solid #d0d0d0' }}>
                      <Input type="number" value={totalProductionSum || ''} readOnly style={{ width: "100%" }} size='md' variant='unstyled' />
                    </td>

                  </tr>
                  {tableData.map((row, rowIndex) => (
                    <tr key={row.storeName} className={activeRow === row.storeName ? 'highlighted' : ''}>
                      <td style={{ fontWeight: 'bold', position: 'sticky', left: 0, zIndex: 1, background: '#F6F6F6' }}>{row.storeName}</td>
                      {row.sizeQuantities.map((sizeData, sizeIndex) => (

                        <td key={sizeData.size} style={{ borderLeft: '1px solid #d0d0d0' }}>
                          <Input
                            type="number"
                            value={sizeData.quantity}
                            onChange={(e) =>
                              handleQuantityChange(e, rowIndex, sizeIndex)
                            }
                            style={{ width: "100%" }}
                            size='md'
                            variant='unstyled'
                            onFocus={() => setActiveRow(row.storeName)}
                            onBlur={() => setActiveRow('')}
                            onWheel={(e) => e.currentTarget.blur()}
                          />
                        </td>
                      ))}
                      <td style={{ borderLeft: '1px solid #d0d0d0' }}>
                        <Input
                          type="number"
                          value={calculateRowTotal(rowIndex) || ''}
                          readOnly
                          style={{ width: "100%" }}
                          size='md'
                          variant='unstyled'
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr style={{ borderBottom: '1px solid #d0d0d0', borderTop: '1px solid #d0d0d0' }}>
                    <td style={{ fontWeight: 'bold', position: 'sticky', left: 0, zIndex: 1, background: '#F6F6F6' }}>Total allocation</td>
                    {sizes.map((_, index) => (
                      <td key={`total-allocation-${index}`} style={{ borderLeft: '1px solid #d0d0d0' }}>
                        <Input type='number' value={calculateTotalAllocation(index) || 0} style={{ width: '100%' }} size='md' variant='unstyled' />
                      </td>
                    ))}
                    <td style={{ borderLeft: '1px solid #d0d0d0' }}> <Input type='number' value={calculateTotalSizeQuantitiesSum() || 0} style={{ width: '100%' }} size='md' variant='unstyled' /></td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bold', position: 'sticky', left: 0, zIndex: 1, background: '#F6F6F6' }}>Overstock</td>
                    {sizes.map((_, index) => (
                      <td key={`overstock-${index}`} style={{ borderLeft: '1px solid #d0d0d0' }}>
                        <Input type='number' value={calculateOverstock(index) || 0} style={{ width: '100%' }} size='md' variant='unstyled' />
                      </td>

                    ))}
                    <td style={{ borderLeft: '1px solid #d0d0d0', borderRight: '1px solid #d0d0d0' }}>
                      <Input type='number' value={Math.max(totalProductionSum - calculateTotalSizeQuantitiesSum(), 0)} style={{ width: '100%' }} size='md' variant='unstyled' />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Grid.Col>
            <Grid.Col offset={1} span={1}>
              <Table id="table-to-print-1" className="table">
                <thead id='table-header'>
                  <tr>
                    <th> STD QTY</th>
                  </tr>
                </thead>
                <tbody style={{ border: '1px solid #d0d0d0', borderRight: '1px solid #d0d0d0' }}>
                  <tr style={{ height: '57px', justifyContent: 'center' }}>
                    <td style={{ borderLeft: '1px solid #d0d0d0' }}>
                      <Input
                        type="number"
                        placeholder=''
                        value={totalProductionSum - calculateTotalStdQtySum()}
                        variant='unstyled'

                      />
                    </td>
                  </tr>
                  {tableData.map((row, rowIndex) => (
                    <tr key={row.storeName} className={activeRow === row.storeName ? 'highlighted' : ''} >
                      <td style={{ borderLeft: '1px solid #d0d0d0' }}>
                        <Input
                          type="number"
                          value={standardQuantities[rowIndex]}
                          onChange={(e) => handleStandardQuantityChange(e, rowIndex)}
                          placeholder=""
                          style={{ width: "100%" }}
                          variant='unstyled'
                          size='md'
                          onFocus={() => setActiveRow(row.storeName)}
                          onBlur={() => setActiveRow('')}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </td>

                    </tr>
                  ))}
                  <tr style={{ borderBottom: '1px solid #d0d0d0', borderTop: '1px solid #d0d0d0' }}>
                    <td style={{ borderLeft: '1px solid #d0d0d0' }}>  <Input type='number' value={calculateTotalStdQtySum() || ''} style={{ width: '100%' }} size='md' variant='unstyled' /></td>
                  </tr>
                  <tr>
                    <td style={{ borderLeft: '1px solid #d0d0d0', borderRight: '1px solid #d0d0d0' }}>

                      <Input type='number' style={{ width: '100%', color: '#ffffff' }} size='md' variant='unstyled' />

                    </td>
                  </tr>
                </tbody>
              </Table>
            </Grid.Col>
          </Grid>
        </Container>
      </MantineProvider>

      <Center style={{ marginTop: "25px", marginBottom: "10px" }} >
        <Group>

          <Button onClick={handleCalculateClick}>Calculate</Button>
          <Button onClick={() => {
            const newTableData = allocateOverstockToStore(tableData, 'STEELES');
            setTableData(newTableData);
          }}>
            Send Overstock to STEELES
          </Button>

          <Button onClick={() => reset()}>Reset</Button>
          <Button onClick={onSave}>Save</Button>
          <Button onClick={downloadAllPDFs}>PRINT</Button>

        </Group>

      </Center>

    </>
  );
};

export default forwardRef(Alloc);