type StorePair = {
    [key: string]: string;
  };

export enum constAllocationInvoice {
    PO_NO = 'Po No',
    SUPPLIER = 'Supplier',
    TOTAL_STYLES = 'Total styles',
    STORE_NAME = 'Store Name',
    STYLE = 'Style',
    QTY = 'Quantity',
    PONO = 'poNo',
    SUPPLIER_NAME = 'supplierName',
    SIZE_QUANTITIES = 'sizeQuantities',
    STORE_NAMES = 'storeName',
    VIEW_MORE = 'View More',
    VIEW_LESS = 'VIew Less',
}

export const STORE_PAIRS: StorePair = {
    "STEELES": "ST",
    "WEB": "WEB",
    "Kingston": "Kingston",
    "VAUGHAN": "V",
    "NIAGARA": "NF",
    "ALLISTON": "AL",
    "SVP QW": "QW",
    "CARTWRIGHT": "CW",
    "Brampton": "BR",
    "PICKERING": "PC",
    "YORKGATE": "YG",
    "OPM-HAMILTON": "OHAM",
    "SC SuperStore": "SCS",
    "ORFUS":"ORFUS",
    "SVP NM": 'SVP NM'
}

// enum RMS_STORES: string[] = ['Niagara', 'Steeles', 'DC-Caledonia', 'Scarborough', 'Queensway', 'Alliston', 'Newmarket', 'Dufferin-Orfus', 'Yorkgate', 'Vaughan', 'DC', 'OPM-Hamilton', 'OPM', 'DC-Queensway', 'Cartwright', 'Web', 'Pickering', 'New-Scarborough', 'Brampton'];
export const RMS_STORE_PAIRS: StorePair = {
    "Steeles": "ST",
    "Web": "WEB",
    "Kingston": "Kingston",
    "Vaughan": "V",
    "Niagara": "NF",
    "Alliston": "AL",
    "Scarborough": "SC",
    "Cartwright": "CW",
    "Brampton": "BR",
    "Pickering": "PC",
    "Yorkgate": "YG",
    "OPM-Hamilton": "OHAM",
    "New-Scarborough": "SCS",
    "Dufferin-Orfus":"ORFUS",
    "Newmarket": 'SVP NM',
    "Queensway": 'QW',
    "DC": 'DC',
    "DC-Caledonia": 'DC-CAL',
    "DC-Queensway": 'DC-QW',
}
